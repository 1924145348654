.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

#header{
  background: #ffffff;
  background-image: url('https://solutions-engineering.s3.amazonaws.com/implementation/berluti/berluti_logo.svg');
  background-repeat: no-repeat;
  background-size: auto 30px;
  background-position-y: center;
  background-position-x: 30px;
}

.App-link {
  color: #09d3ac;
}